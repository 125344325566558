export const productionConfig = {
  API: {
    ROOT_URL: 'https://api.econsult.health',
    CUSTOMISATION_URL: 'https://api.econsult.health/customisation',
  },
  PRIMARY_CARE_DOMAIN: 'webgp.com',
  COOKIES: {
    DOMAIN: 'econsult.health',
  },
  INTERCOM: {
    ENABLED: true,
    APP_ID: 'f9w7aj87',
  },
};
