import React from 'react';
import theme from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/globalStyles';
import { Router } from 'routes';
import { StoreProvider } from 'store/configureStore';

const App = () => (
  <StoreProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  </StoreProvider>
);

export default App;
