import { STRINGS } from 'config';
import { redirectUserToLoginScreen } from 'store/action/auth';
import request from 'superagent';

const {
  API: { ROOT_URL },
  USER_API_ADD_STATUS_CODES: { USER_ALREADY_EXISTS },
  USER_API_DELETE_STATUS_CODES: {
    PRACTICE_IN_JWT_MISSING_OR_INVALID,
    USER_CANNOT_DELETE_THEMSELVES,
    USER_EXISTS_IN_ANOTHER_ORGANISATION,
  },
  REDUCERS: {
    USER_MANAGEMENT: {
      API_DELETE_USER_REQUEST,
      API_DELETE_USER_SUCCESS,
      API_DELETE_USER_ERROR,
      API_GET_USERS_REQUEST,
      API_GET_USERS_ERROR,
      API_GET_USERS_SUCCESS,
      API_POST_ADD_USER_REQUEST,
      API_POST_ADD_USER_SUCCESS,
      API_POST_ADD_USER_ERROR,
    },
  },
} = STRINGS;

export function apiGetUsersFromCurrentPractice({ practiceId, dispatch }) {
  dispatch({ type: API_GET_USERS_REQUEST });
  request
    .get(`${ROOT_URL}/usermanagement/users/practice/${practiceId}`)
    .withCredentials()
    .then(resp => {
      dispatch({
        type: API_GET_USERS_SUCCESS,
        payload: resp.body,
      });
    })
    .catch(() => {
      dispatch({
        type: API_GET_USERS_ERROR,
      });
      return redirectUserToLoginScreen();
    });
}

export function apiDeleteUser({ practiceId, email, dispatch }) {
  dispatch({ type: API_DELETE_USER_REQUEST });
  return request
    .delete(`${ROOT_URL}/usermanagement/users/${email}`)
    .withCredentials()
    .query({ practiceId: practiceId })
    .then(resp => {
      dispatch({
        type: API_DELETE_USER_SUCCESS,
        payload: email,
      });
      return resp;
    })
    .catch(err => {
      dispatch({
        type: API_DELETE_USER_ERROR,
        payload: err.response.status,
      });
      if (![
        PRACTICE_IN_JWT_MISSING_OR_INVALID,
        USER_CANNOT_DELETE_THEMSELVES,
        USER_EXISTS_IN_ANOTHER_ORGANISATION,
      ].includes(err.response.status)) {
        return redirectUserToLoginScreen();
      }
      return err;
    });
}

export function apiAddUser({ practiceId, email, name, dispatch }) {
  dispatch({ type: API_POST_ADD_USER_REQUEST });
  return request
    .post(`${ROOT_URL}/usermanagement/users/`)
    .withCredentials()
    .send({
      practiceId,
      email,
      name,
    })
    .then(resp => {
      dispatch({
        type: API_POST_ADD_USER_SUCCESS,
        payload: { name, email },
      });
      return resp;
    })
    .catch(err => {
      dispatch({
        type: API_POST_ADD_USER_ERROR,
        payload: err.response.status,
      });
      if (err.response.status !== USER_ALREADY_EXISTS) {
        return redirectUserToLoginScreen();
      }
      return err;
    });
}
