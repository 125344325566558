import styled from 'styled-components';
import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';

export const TextFlow = styled.div`
  display: flex;
  flex-direction: column;

  && > * {
    margin: 0;
  }
  
  && > * + * {
    margin-top: 0.8rem;
  }
`;

export const Heading = styled(Paragraph).attrs(({ as }) => ({ as: (as || 'h1'), typography: 'ParagraphSmallHeavy' }))`
  color: ${({ theme }) => theme.colorPrimaryPrimary80};
`;

export const ParagraphXSmallRegular = styled(Paragraph).attrs({ typography: 'ParagraphXsmallRegular' })`
  margin: 0;
  color: ${({ theme }) => theme.colorPrimaryPrimary70};
`;

export const ParagraphXSmallHeavy = styled(Paragraph).attrs({ typography: 'ParagraphXsmallHeavy' })`
  margin: 0;
  color: ${({ theme }) => theme.colorPrimaryPrimary80};
`;

export const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colorPrimaryPrimary10};
`;

export const InlineLink = styled.a`
  font-weight: 600;

  &,
  &:visited {
    color: ${({ theme }) => theme.colorActionPrimaryDefault};
  }

  &:hover {
    color: ${({ theme }) => theme.colorActionPrimaryHover};
  }

  &:active {
    color: ${({ theme }) => theme.colorActionPrimaryActive};
  }
`;
