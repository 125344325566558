import React from 'react';
import { STRINGS } from 'config';
import request from 'superagent';
import { toast } from '@econsult/econsult-ui/dist/components/Toast';
import { ErrorToast, SuccessToast } from 'components/Toast';
import { reportError } from 'utils/datadog';

const {
  API: { CUSTOMISATION_URL },
  FEATURES: { ADMIN_AVAILABILITY, DEMAND_MANAGEMENT_ENABLED },
  FEATURE_VALUES,
  REDUCERS: {
    DEMAND_MANAGEMENT: {
      UPDATE_ADMIN_AVAILABILITY,
      API_GET_ADMIN_AVAILABILITY_REQUEST,
      API_GET_ADMIN_AVAILABILITY_SUCCESS,
      API_GET_ADMIN_AVAILABILITY_ERROR,
      API_POST_ADMIN_AVAILABILITY_REQUEST,
      API_POST_ADMIN_AVAILABILITY_SUCCESS,
      API_POST_ADMIN_AVAILABILITY_ERROR,
      GET_DEMAND_MANAGEMENT_ENABLED_REQUEST,
      GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS,
      GET_DEMAND_MANAGEMENT_ENABLED_ERROR,
    },
  },
} = STRINGS;

export const updateAdminAvailability = payload => (
  ({ type: UPDATE_ADMIN_AVAILABILITY, payload })
);

export const apiGetAdminAvailability = practiceId => dispatch => {
  dispatch({ type: API_GET_ADMIN_AVAILABILITY_REQUEST });
  return request.get(`${CUSTOMISATION_URL}/feature/${ADMIN_AVAILABILITY}/${practiceId}`)
    .withCredentials()
    .set({ 'X-OWNER-ID': practiceId })
    .then(response => {
      const isValidForFeature = Object.keys(FEATURE_VALUES.ADMIN_AVAILABILITY).includes(response.body.value);

      if (!isValidForFeature) {
        reportError(new Error(`invalid value for feature ${ADMIN_AVAILABILITY}`), {
          practiceId,
          feature: ADMIN_AVAILABILITY,
          featureValue: response.body.value,
        });

        return dispatch({ type: API_GET_ADMIN_AVAILABILITY_SUCCESS, payload: null });
      }

      return dispatch({ type: API_GET_ADMIN_AVAILABILITY_SUCCESS, payload: response.body.value });
    })
    .catch(error => {
      dispatch({ type: API_GET_ADMIN_AVAILABILITY_ERROR, payload: error });
    });
};

export const apiPostAdminAvailability = (practiceId, value) => dispatch => {
  if (!value) {
    toast.error(<ErrorToast message="There was an error. Please try again." />, { icon: false, autoClose: 12000 });

    reportError(new Error(`could not save value for feature ${ADMIN_AVAILABILITY}`), {
      practiceId,
      feature: ADMIN_AVAILABILITY,
      featureValue: value,
    });

    return ({ type: API_POST_ADMIN_AVAILABILITY_ERROR });
  }

  dispatch({ type: API_POST_ADMIN_AVAILABILITY_REQUEST });

  return request.post(`${CUSTOMISATION_URL}/public/feature/value`)
    .withCredentials()
    .set({ 'X-OWNER-ID': practiceId })
    .send({
      featureId: ADMIN_AVAILABILITY,
      value,
    })
    .then(response => {
      dispatch({ type: API_POST_ADMIN_AVAILABILITY_SUCCESS, payload: response.body.value });
      toast(<SuccessToast message="Settings saved successfully" />);
    })
    .catch(error => {
      reportError(new Error(`could not save value for feature ${ADMIN_AVAILABILITY}`), {
        practiceId,
        feature: ADMIN_AVAILABILITY,
        featureValue: value,
        error,
      });

      dispatch({ type: API_POST_ADMIN_AVAILABILITY_ERROR, payload: error });
      toast.error(<ErrorToast message="There was an error. Please try again." />, { icon: false, autoClose: 12000 });
    });
};

export const getDemandManagementEnabled = practiceId => dispatch => {
  dispatch({ type: GET_DEMAND_MANAGEMENT_ENABLED_REQUEST });
  return request
    .get(`${CUSTOMISATION_URL}/feature/${DEMAND_MANAGEMENT_ENABLED}/${practiceId}`)
    .set({ 'X-OWNER-ID': practiceId })
    .then(response => dispatch({ type: GET_DEMAND_MANAGEMENT_ENABLED_SUCCESS, payload: response.body.value }))
    .catch(error => dispatch({ type: GET_DEMAND_MANAGEMENT_ENABLED_ERROR, payload: error }));
};
