import styled from 'styled-components';
import { Div, ColumnDiv } from '@econsult/econsult-ui/dist/components/shared/div';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { HeadingFour, Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import theme from 'styles/theme';
import { AnchoredTooltip } from '@econsult/econsult-ui/dist/components/AnchoredTooltip';

export const TableHeaderDivider = styled(Div)`
  width: 100%;
  padding: 16px 0 0;
  margin-bottom: 16px;
  border-bottom: ${theme.colorNeutralNeutral4} solid 2px;
`;

export const TableContainer = styled(ColumnDiv)`
  width: 944px;
`;

export const TableActionButton = styled(Button)`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 6px;
  border: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${({ isDisabled }) => (isDisabled ? theme.colorPrimaryPrimary10 : theme.colorActionNeutralDefault)};
  svg {
    color: ${({ isDisabled }) => (isDisabled ? theme.colorPrimaryPrimary40 : theme.colorPrimaryPrimary100)};
    margin: 0;
  }
  &:hover {
    svg {
      color: ${({ isDisabled }) => (isDisabled ? theme.colorPrimaryPrimary40 : theme.colorNeutralBrightWhite)};
    }
  }
`;

export const ActionButtonsContainer = styled(Div)`
  justify-content: flex-end;
  && {
    gap: 16px;
  }
`;

export const StyledDataCell = styled(TableCell)`
  && {
    color: ${({ $emphasis }) => ($emphasis ? theme.colorPrimaryPrimary80 : theme.colorPrimaryPrimary70)};
    font-weight: ${({ $emphasis }) => ($emphasis ? 800 : 400)};
    font-family: ${theme.fontFamilyPrimary};
    font-style: normal;
    line-height: 120%;
    padding: 12px 4px;
  }
`;

export const TableHeaderTitleText = styled(HeadingFour)`
  color: ${theme.colorPrimaryPrimary80};
  font-family: ${theme.fontFamilyPrimary};
  font-size: 28px;
  font-weight: 600;
  margin: 8px 0;
`;

export const StyledTableRow = styled(TableRow)`
  && {
    height: 48px;
  }
`;

export const StyledColumnHeaderCell = styled(TableCell)`
  && {
    color: ${theme.colorPrimaryPrimary70};
    font-family: ${theme.fontFamilyPrimary};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    padding: 16px 4px;
  }
`;

export const TableHeaderSmallText = styled(Paragraph).attrs({ typography: 'ParagraphXsmallRegular' })`
  color: ${theme.colorPrimaryPrimary70};
  padding: 0;
  margin: 0;
  font-weight: 400;
`;

export const CustomAnchoredToolTip = styled(AnchoredTooltip)`
  z-index: 33;
  *:focus > && {
    opacity: 1;
    transform:
      ${({ align }) => {
    switch (align) {
      case 'top':
        return 'translateX(-50%) translateY(-0.125rem);';
      case 'bottom':
        return 'translateX(-50%) translateY(0.125rem);';
      case 'left':
        return 'translateX(calc(-100% - 0.625rem)) translateY(-50%);';
      case 'right':
        return 'translateX(0.625rem) translateY(-50%);';
      default:
        return 'translateX(-50%) translateY(-0.125rem);';
    }
  }};
  }
`;
