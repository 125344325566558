import React, { useEffect, useState } from 'react';
import { ModalTitle } from 'components/Modal/style';
import {
  UserOptionsModal,
  StyledModalContents,
  ModalTitleAndCloseSection,
} from 'components/Modal/userModal';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { apiDeleteUser } from 'store/action/userManagement';
import { DeleteUserModalContent } from 'components/UserTable/DeleteUserModalContent';
import { STRINGS } from 'config';

const EXPECTED_ERROR_CODES = [
  STRINGS.USER_API_DELETE_STATUS_CODES.PRACTICE_IN_JWT_MISSING_OR_INVALID,
  STRINGS.USER_API_DELETE_STATUS_CODES.USER_CANNOT_DELETE_THEMSELVES,
  STRINGS.USER_API_DELETE_STATUS_CODES.USER_EXISTS_IN_ANOTHER_ORGANISATION,
];

const initialState = {
  shouldBlockUserActions: false,
};

export const DeleteUserModal = ({ isOpen, onClose, selectedEmail }) => {
  const [shouldBlockUserActions, setShouldBlockUserActions] = useState(initialState.shouldBlockUserActions);
  const state = useAppState();
  const dispatch = useAppDispatch();
  const { app: { selectedPractice }, userManagement: { userDeleteStatus } } = state;

  function onCloseCleanUp() {
    setShouldBlockUserActions(initialState.shouldBlockUserActions);
    onClose();
  }

  const handleDeleteUser = () => {
    setShouldBlockUserActions(true);
    return apiDeleteUser({
      practiceId: selectedPractice?.id,
      email: selectedEmail,
      dispatch,
    })
      .then(resp => {
        if (resp.status === STRINGS.USER_API_DELETE_STATUS_CODES.USER_DELETED_SUCCESS) {
          onCloseCleanUp();
        }
      });
  };

  useEffect(() => {
    if (EXPECTED_ERROR_CODES.includes(userDeleteStatus)) {
      return setShouldBlockUserActions(false);
    }
    return () => {};
  }, [userDeleteStatus]);

  const handleOnCloseDeleteModal = () => !shouldBlockUserActions && onCloseCleanUp();

  return (
    <UserOptionsModal
      isOpen={isOpen}
      onClose={handleOnCloseDeleteModal}
      modalContainerDataAutomationName="DeleteUserModal"
      id="UserOptionsModalId"
      aria-describedby="UserOptionsModalId"
      role="dialog"
    >
      <ModalTitleAndCloseSection disabled={shouldBlockUserActions} style={{ borderBottom: 'none' }}>
        {!userDeleteStatus
          ? <ModalTitle data-automation="ModalTitleConfirm">Confirm</ModalTitle>
          : <ModalTitle data-automation="ModalTitleError">Error</ModalTitle>}
      </ModalTitleAndCloseSection>
      <StyledModalContents>
        <DeleteUserModalContent
          onClose={handleOnCloseDeleteModal}
          handleSubmitDeleteUser={handleDeleteUser}
          userDeleteStatus={userDeleteStatus}
          shouldBlockUserActions={shouldBlockUserActions}
        />
      </StyledModalContents>
    </UserOptionsModal>
  );
};
