import React from 'react';
import { ErrorIcon } from '@econsult/econsult-ui/dist/assets';
import {
  ActionButtonContainer,
  CancelButton,
  ErrorContainer,
  ErrorContainerRow,
  ErrorMessage,
  ModalBodyContainer,
  UserForwardActionButton,
} from 'components/Modal/userModal';
import { FormInput } from 'components/FormInput';

const ErrorMessageBlock = () => (
  <ErrorContainer data-automation="ErrorContainer">
    <ErrorContainerRow>
      <ErrorIcon />
      <ErrorMessage style={{ marginLeft: '16px' }}>
        Sorry this user already exists. Please contact eConsult via Live Chat to add this user to your
        practice.
      </ErrorMessage>
    </ErrorContainerRow>
  </ErrorContainer>
);

export const AddUserModalContent = ({
  onClose,
  fullName,
  emailAddress,
  fullNameValidationError,
  emailValidationError,
  onChangeFullName,
  onChangeEmailAddress,
  handleSubmitAddUser,
  userCreationError,
  shouldBlockUserActions,
}) => (
  <ModalBodyContainer>
    { userCreationError && <ErrorMessageBlock /> }
    <FormInput
      id="nameTextInput"
      label="Full name"
      maxLength={300}
      type="text"
      value={fullName || ''}
      onChange={onChangeFullName}
      showError={fullNameValidationError}
      message={fullNameValidationError && fullNameValidationError}
      autoFocus={false}
      automationName="FullNameInput"
      disabled={shouldBlockUserActions}
    />
    <FormInput
      id="emailAddressTextInput"
      label="Email address"
      maxLength={50}
      type="text"
      value={emailAddress || ''}
      onChange={onChangeEmailAddress}
      showError={emailValidationError}
      message={emailValidationError && emailValidationError}
      autoFocus={false}
      automationName="EmailAddressInput"
      disabled={shouldBlockUserActions}
    />
    <ActionButtonContainer>
      <CancelButton
        disabled={shouldBlockUserActions}
        onClick={onClose}
        data-automation="CancelAddUserButton"
        type="button"
      >Cancel
      </CancelButton>
      <UserForwardActionButton
        disabled={shouldBlockUserActions}
        onClick={handleSubmitAddUser}
        data-automation="AddUserButton"
        type="button"
      >Add user
      </UserForwardActionButton>
    </ActionButtonContainer>
  </ModalBodyContainer>
);
