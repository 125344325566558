import { FEATURE_VALUES } from 'config/featureValues';
import { FEATURES } from 'config/features';
import { REDUCERS } from 'config/reducers';

export const STRINGS = {
  API: {
    ROOT_URL: 'https://settings.econsult.awshealth.net',
    CUSTOMISATION_URL: 'https://api.econsult.health/customisation',
  },
  DEMAND_MANAGEMENT_HELP_URL: 'https://help.econsult.health/en/articles/5317015-changing-consultation-access-for-patients-demand-management',
  COOKIEFIRST: {
    ENABLED: true,
  },
  COOKIES: {
    DOMAIN: 'awshealth.net',
  },
  ENVIRONMENT: {
    IS_DEV_OR_TEST_ENV: process.env.REACT_APP_ENVIRONMENT !== 'production',
    IS_LOCAL: process.env.REACT_APP_ENVIRONMENT === undefined,
  },
  USER_API_ADD_STATUS_CODES: {
    INCOMPLETE_REQUEST_BODY: 400,
    PRACTICE_IN_JWT_MISSING_OR_INVALID: 403,
    USER_ADDED_SUCCESS: 200,
    USER_ALREADY_EXISTS: 409,
  },
  USER_API_DELETE_STATUS_CODES: {
    PRACTICE_IN_JWT_MISSING_OR_INVALID: 403,
    USER_CANNOT_DELETE_THEMSELVES: 409,
    USER_DELETED_SUCCESS: 200,
    USER_EXISTS_IN_ANOTHER_ORGANISATION: 422,
  },
  DATADOG: {
    ID: 'pubab9cd6d45649d03eef11c681ec22c076',
  },
  INTERCOM: {
    ENABLED: true,
    APP_ID: 'wmmrp56d', // eConsult Health [TEST]
  },
  REQUEST_STATUS: {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
  },
  REDUCERS,
  FEATURES,
  FEATURE_VALUES,
};
