import React from 'react';
import { TableActionButton, CustomAnchoredToolTip } from 'components/Table';
import { StyledEConsultDeleteIcon } from 'components/Modal/userModal';

export const DeleteUserButton = ({ handleTableActionDeleteUser, selectedEmail, isDisabled }) => (
  <TableActionButton
    isDisabled={isDisabled}
    disabled={isDisabled}
    onClick={() => handleTableActionDeleteUser(selectedEmail)}
    data-automation="TableActionDeleteButton"
    aria-label="Delete Button"
    type="button"
  >
    <StyledEConsultDeleteIcon disabled={isDisabled} />
    <CustomAnchoredToolTip
      automationLabel="deleteUserTooltip"
      label={isDisabled ? 'Contact your practice admin' : 'Delete User'}
      align="bottom"
    />
  </TableActionButton>
);
