import styled from 'styled-components';
import {
  ModalContents,
  ModalTitleAndClose,
  Modal,
} from 'components/Modal';
import { ColumnDiv } from '@econsult/econsult-ui/dist/components/shared/div';
import { Div } from 'components/Div';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import theme from 'styles/theme';
import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import { EConsultDeleteIcon2 } from '@econsult/econsult-ui/dist/assets';

export const UserOptionsModal = styled(Modal)`
  width: 512px;
  height: auto;
`;

export const ModalTitleAndCloseSection = styled(ModalTitleAndClose)`
  padding: 25px 40px 20px 40px;
`;

export const StyledModalContents = styled(ModalContents)`
  padding: 0;
  border: unset;
  background: unset;
`;

export const ModalBodyContainer = styled(ColumnDiv)`
  padding-top: 16px;
  gap: 16px;
`;

export const ActionButtonContainer = styled(Div)`
  justify-content: ${({ hasError }) => (hasError ? 'center' : 'flex-end')};
  margin-top: 16px;
`;

export const UserForwardActionButton = styled(Button).attrs({ state: 'secondary', size: 'small' })``;

export const CancelButton = styled(Button).attrs({ size: 'small', fillStyle: 'borderless' })`
  margin-right: 8px;
`;

export const ErrorContainer = styled(Div)`
  padding: 12px 32px 12px 16px;
  display: flex;
  align-items: center;  
  background-color: ${theme.colorStatusError};
  color: ${theme.colorNeutralBrightWhite};
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const ErrorContainerRow = styled(Div)``;

export const ErrorMessage = styled(Paragraph).attrs({
  typography: 'ParagraphTinyRegular',
})`
  margin: 0;
`;

export const BodySmallRegText = styled(Paragraph).attrs({ typography: 'ParagraphXsmallRegular' })`
  color: ${theme.colorPrimaryPrimary80};
  padding: 0;
  margin: 0;
  font-weight: 400;
`;

export const StyledEConsultDeleteIcon = styled(EConsultDeleteIcon2)`
    color: ${({ isDisabled }) => (isDisabled ? 'theme.colorPrimaryPrimary40' : 'theme.colorPrimaryPrimary40')} !important;
`;
