import React from 'react';
import { HeaderColumn, HeaderColumnRight, HeaderRow } from 'components/Table/tableHeader';
import { TableHeaderDivider, TableHeaderSmallText, TableHeaderTitleText } from 'components/Table';
import { AddNewUserButton } from 'components/UserTable/AddNewUserButton';

export const TableHeader = ({ totalUsers, dispatch, selectedPracticeHasAdminRights }) => (
  <HeaderColumn>
    <HeaderRow>
      <HeaderColumn role="heading" aria-level="1">
        <TableHeaderTitleText data-automation="TableHeaderTitleText">
          User management
        </TableHeaderTitleText>
        <TableHeaderSmallText data-automation="TableHeaderTitleSubText">
          Manage the list of users in your practice using the Smart Inbox.
        </TableHeaderSmallText>
      </HeaderColumn>
      <HeaderColumnRight>
        <AddNewUserButton
          dispatch={dispatch}
          selectedPracticeHasAdminRights={selectedPracticeHasAdminRights}
        />
      </HeaderColumnRight>
    </HeaderRow>

    <TableHeaderDivider />
    <TableHeaderSmallText data-automation="TableHeaderResultCount">
      {totalUsers} users in your practice
    </TableHeaderSmallText>
  </HeaderColumn>
);
