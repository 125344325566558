import { ParagraphXSmallRegular } from 'components/Typography';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Legend = styled(ParagraphXSmallRegular).attrs({ as: 'legend' })`
  padding: 0;
`;
