import { STRINGS } from 'config';
import _map from 'lodash/map';
import request from 'superagent';
import { updateIntercom } from 'utils/intercom';

const {
  API: { ROOT_URL },
  REDUCERS: {
    APP: { SET_USER_DATA },
    AUTH: {
      API_CHECK_AUTHENTICATION_REQUEST,
      API_CHECK_AUTHENTICATION_SUCCESS,
      CHECK_AUTHENTICATION_ERROR,
    },
  },
} = STRINGS;

const color = 'colorExpressionExpression1';

const getOrgName = (id, orgDetails) => {
  if (orgDetails) {
    return orgDetails?.find(ea => ea.id === id)?.prettyName;
  }
  return id;
};

const getOrgPcmCode = (id, orgDetails) => orgDetails?.find(ea => ea.id === id)?.pcmCode;

export const redirectUserToLoginScreen = () => {
  window.location.href = `${ROOT_URL}/auth/logout`;
};

export function apiGetAuthenticationCheck(dispatch) {
  dispatch({ type: API_CHECK_AUTHENTICATION_REQUEST });
  return request
    .get(`${ROOT_URL}/usermanagement/users/current`)
    .withCredentials()
    .then(resp => {
      dispatch({ type: API_CHECK_AUTHENTICATION_SUCCESS });
      const { orgIds, email, orgDetails } = resp?.body;
      const { practiceIds, ehubIds, admin } = orgIds;
      if (!practiceIds && !ehubIds) {
        return redirectUserToLoginScreen();
      }
      const practices = practiceIds ? practiceIds.map(ea => ({
        prettyName: getOrgName(ea, orgDetails),
        pcmCode: getOrgPcmCode(ea, orgDetails),
        id: ea,
        color,
      })) : [];
      const ehubs = ehubIds ? ehubIds.map(ea => ({
        prettyName: getOrgName(ea, orgDetails),
        id: ea,
        color,
      })) : [];
      const adminRights = admin || [];
      if (email) {
        updateIntercom({
          email,
          user_id: email,
          name: email,
          companies: _map([...practices, ...ehubs], ea => ({ id: ea.id, name: ea.id })),
        });
      }
      return dispatch({
        type: SET_USER_DATA,
        payload: { practices, ehubs, adminRights, currentUserEmail: email },
      });
    })
    .catch(err => {
      dispatch({ type: CHECK_AUTHENTICATION_ERROR, payload: err });
      return redirectUserToLoginScreen();
    });
}
