import { Modal as MuiModal } from '@econsult/econsult-ui/dist/components/Modal';
import { Div, ColumnDiv, ScrollableContainerInner, ScrollableContainerOuter } from 'components/Div';
import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import styled from 'styled-components';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { rgba } from 'polished';
import { CloseIcon } from '@econsult/econsult-ui/dist/assets';
import theme from 'styles/theme';

export const InvisibleButton = styled.button`
  padding: 5px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ModalContainer = styled(ColumnDiv)`
  background: ${theme.colorNeutralBrightWhite};
  border-radius: 6px;
  width: 50%;
  height: 50%;
  overflow-y: auto;
`;

export const ModalTitleContainer = styled(Div)`
  background-color: ${theme.colorNeutralBrightWhite};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 33px 52px 20px 48px;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitleAndCloseContainer = styled(Div)`
  background-color: ${theme.colorNeutralBrightWhite};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 33px 52px 20px 48px;
  border-bottom: ${theme.dividerMatchingRight};
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled(Paragraph).attrs({ typography: 'ParagraphBaseHeavy' })`
  ${theme.fontStyles};
  color: ${theme.brandingPrimaryShadeFour};
  font-weight: ${theme.fontWeight600};
  margin: 0;
`;

export const ModalContentsOuter = styled(ColumnDiv)``;

export const ModalContentsInner = styled(ColumnDiv)`
  flex: 1 1 auto;
  margin: 0 40px 20px 40px;
  padding: 24px;
  border-radius: 6px;
  background: ${theme.colorNeutralBrightWhite};
  font-weight: ${theme.fontWeight600};
  font-size: ${theme.fontSizeMedium};
  color: ${theme.dropdownPrimary};
`;

export const ModalCloseButton = styled(InvisibleButton)`
  color: ${theme.wrapperModalCloseButton};
  border: 2px solid transparent;
  outline: none;
  padding: 0;
  &:focus {
    border: 2px solid ${theme.wrapperModalCloseButton};
  }
  &:active {
    outline: none;
  }
`;

export const ModalCloseIcon = styled(CloseIcon)`
  margin: 0;
  padding: 0;
`;

export const ModalScrollableContainerOuter = styled(ScrollableContainerOuter)`
  flex: 1 1 auto;
  margin-bottom: 1px;
  overflow: visible;
`;

export const ModalScrollableContainerInner = styled(ScrollableContainerInner)`
  position: relative;
`;

export const ModalConfirmButton = styled(Button).attrs({ size: 'small' })`
  cursor: pointer;
  border-radius: 6px;
`;

export const ModalCancelButton = styled(ModalConfirmButton).attrs({ state: 'secondary' })``;

export const ModalActionsContainer = styled(Div)`
  justify-content: center;
  gap: 8px;
  padding-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

export const RevertButton = styled(Button).attrs({ size: 'small' })`
  && {
    color: ${theme.colorActionPrimaryDefault};
    background: ${theme.colorPrimaryPrimary20};
    border-color: ${theme.colorPrimaryPrimary20};
    cursor: pointer;
  }
`;

export const ConfirmButton = styled(Button).attrs({ size: 'small' })`
  && {
    background: ${theme.colorStatusError};
    border-color: ${theme.colorStatusError};
    cursor: pointer;

    &&[disabled] {
      background-color: ${rgba(theme.colorStatusError, 0.1)} !important;
      color: ${rgba(theme.colorStatusError, 0.5)} !important;
      border-color: transparent !important;
    }
  }
`;

export { MuiModal };
