import { appReducer, initialAppState } from 'store/reducer/app';
import { authReducer, initialAuthState } from 'store/reducer/auth';
import { demandManagementReducer, initialDemandManagementState } from 'store/reducer/demandManagement';
import { initialUserManagementState, userManagementReducer } from 'store/reducer/userManagement';

export const rootState = {
  app: initialAppState,
  auth: initialAuthState,
  userManagement: initialUserManagementState,
  demandManagement: initialDemandManagementState,
};

const combineReducers = reducers => (state, action) => Object.keys(reducers).reduce((prevState, key) => ({
  ...prevState,
  [key]: reducers[key](prevState[key], action),
}), state);

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  userManagement: userManagementReducer,
  demandManagement: demandManagementReducer,
});
