import { v4 as uuidv4 } from 'uuid';
import { DeleteUserButton } from 'components/UserTable/DeleteUserButton';
import {
  ActionButtonsContainer,
  StyledDataCell,
  StyledTableRow,
} from 'components/Table';
import React from 'react';

export const UserTableRow = ({ row, handleDelete, selectedPracticeHasAdminRights }) => {
  const deleteButtonDisabled = !selectedPracticeHasAdminRights;
  return (
    <StyledTableRow key={uuidv4()} data-automation="TableDataRow">
      <StyledDataCell
        scope="row"
        key={uuidv4()}
        fontSize={14}
        $emphasis
        data-automation="TableDataCellName"
      >
        {(!row.name || row.name.includes('@')) ? 'Not set' : row.name.trim()}
      </StyledDataCell>
      <StyledDataCell
        scope="row"
        key={uuidv4()}
        fontSize={14}
        data-automation="TableDataCellEmail"
      >
        {row.email}
      </StyledDataCell>
      <StyledDataCell
        key={uuidv4()}
      >
        <ActionButtonsContainer data-automation="TableActionButtonsContainer">
          <DeleteUserButton
            handleTableActionDeleteUser={handleDelete(row.email)}
            selectedEmail={row.email}
            isDisabled={deleteButtonDisabled}
          />
        </ActionButtonsContainer>
      </StyledDataCell>
    </StyledTableRow>
  );
};
