import React, { useEffect, useState } from 'react';
import { ModalTitle } from 'components/Modal/style';
import { ModalTitleAndCloseSection, StyledModalContents, UserOptionsModal } from 'components/Modal/userModal';
import { isValidEmail, isValidTextOrNumberString } from 'utils/string';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { apiAddUser } from 'store/action/userManagement';
import { AddUserModalContent } from 'components/UserTable/AddUserModalContent';
import { STRINGS } from 'config';

const {
  REDUCERS: {
    USER_MANAGEMENT: {
      RESET_USER_CREATION_STATUS,
    },
  },
  USER_API_ADD_STATUS_CODES: {
    USER_ADDED_SUCCESS,
    USER_ALREADY_EXISTS,
  },
} = STRINGS;

export const initialState = {
  emailAddress: '',
  fullName: '',
  emailValidationError: null,
  fullNameValidationError: null,
  shouldBlockUserActions: false,
  userCreationError: false,
};

export const AddUserModal = ({ isOpen, onClose }) => {
  const [emailAddress, setEmailAddress] = useState(initialState.emailAddress);
  const [emailValidationError, setEmailValidationError] = useState(initialState.emailValidationError);
  const [fullName, setFullName] = useState(initialState.fullName);
  const [fullNameValidationError, setFullNameValidationError] = useState(initialState.fullNameValidationError);
  const [shouldBlockUserActions, setShouldBlockUserActions] = useState(initialState.shouldBlockUserActions);
  const [userCreationError, setUserCreationError] = useState(initialState.userCreationError);

  const state = useAppState();
  const dispatch = useAppDispatch();
  const { app: { selectedPractice }, userManagement: { userCreationStatus } } = state;

  function onCloseCleanUp() {
    setUserCreationError(initialState.userCreationError);
    setShouldBlockUserActions(initialState.shouldBlockUserActions);
    setEmailAddress(initialState.emailAddress);
    setFullName(initialState.fullName);
    setEmailValidationError(false);
    setFullNameValidationError(false);
    onClose();
  }

  const handleSubmitAddUser = () => {
    dispatch({
      type: RESET_USER_CREATION_STATUS,
    });
    setUserCreationError(false);
    setEmailValidationError(false);
    setFullNameValidationError(false);
    setShouldBlockUserActions(true);
    const trimmedEmail = emailAddress.trim();
    const trimmedFullName = fullName.trim();
    setEmailAddress(trimmedEmail);
    setFullName(trimmedFullName);
    if (!isValidEmail(trimmedEmail)) {
      setEmailValidationError('Please enter a valid email address');
    }
    if (!isValidTextOrNumberString(trimmedFullName)) {
      setFullNameValidationError('Please enter a valid name');
    }
    if (!isValidEmail(trimmedEmail) || !isValidTextOrNumberString(trimmedFullName)) {
      return setShouldBlockUserActions(false);
    }
    return apiAddUser({
      practiceId: selectedPractice?.id,
      email: trimmedEmail,
      name: trimmedFullName,
      dispatch,
    })
      .then(resp => {
        if (resp.status === USER_ADDED_SUCCESS) {
          onCloseCleanUp();
        }
      });
  };

  const onChangeFullName = e => {
    setFullNameValidationError(false);
    setUserCreationError(false);
    setFullName(e.target.value);
  };
  const onChangeEmailAddress = e => {
    setEmailValidationError(false);
    setUserCreationError(false);
    setEmailAddress(e.target.value);
  };

  useEffect(() => {
    if (userCreationStatus === USER_ALREADY_EXISTS) {
      setShouldBlockUserActions(false);
      setUserCreationError(true);
    }
    return () => {};
  }, [userCreationStatus]);

  const handleOnCloseUserOptionsModal = () => !shouldBlockUserActions && onCloseCleanUp();

  return (
    <UserOptionsModal
      isOpen={isOpen}
      onClose={handleOnCloseUserOptionsModal}
      modalContainerDataAutomationName="AddNewUserModal"
      id="UserOptionsModalId"
      aria-describedby="UserOptionsModalId"
      role="dialog"
    >
      <ModalTitleAndCloseSection disabled={shouldBlockUserActions}>
        <ModalTitle data-automation="ModalTitleAddNewUser">Add new user</ModalTitle>
      </ModalTitleAndCloseSection>
      <StyledModalContents>
        <AddUserModalContent
          onClose={handleOnCloseUserOptionsModal}
          emailAddress={emailAddress}
          emailValidationError={emailValidationError}
          onChangeEmailAddress={onChangeEmailAddress}
          fullName={fullName}
          fullNameValidationError={fullNameValidationError}
          onChangeFullName={onChangeFullName}
          handleSubmitAddUser={handleSubmitAddUser}
          userCreationError={userCreationError}
          shouldBlockUserActions={shouldBlockUserActions}
        />
      </StyledModalContents>
    </UserOptionsModal>
  );
};
