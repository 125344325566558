/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useContext, createContext } from 'react';
import {
  ModalContainer,
  ModalTitleAndCloseContainer,
  ModalTitleContainer,
  ModalContentsInner,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContentsOuter,
  MuiModal,
  ModalScrollableContainerInner,
  ModalScrollableContainerOuter,
  ModalTitle,
  ModalConfirmButton,
  ModalCancelButton,
  ModalActionsContainer,
} from 'components/Modal/style';

const ModalContext = createContext();
ModalContext.displayName = 'ModalContext';

function useModalContext() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a <Modal />');
  }
  return context;
}
export const ModalContents = ({ children, ...props }) => (
  <ModalContentsOuter {...props}>
    <ModalContentsInner {...props}>
      {children}
    </ModalContentsInner>
  </ModalContentsOuter>
);

export const ModalTitleAndClose = ({ children, ...props }) => {
  const { onClose } = useModalContext();
  const { disabled } = props;
  return (
    <ModalTitleAndCloseContainer {...props}>
      {children}
      <ModalCloseButton disabled={disabled} data-automation="ModalCloseButton" type="button" tabIndex="0" onClick={onClose}>
        <ModalCloseIcon />
      </ModalCloseButton>
    </ModalTitleAndCloseContainer>
  );
};

export const ModalTitleNoClose = ({ children, ...props }) => (
  <ModalTitleContainer {...props}>
    {children}
  </ModalTitleContainer>
);

export const Modal = props => {
  const {
    onClose,
    isOpen,
    children,
    className,
    modalContainerDataAutomationName,
    ...remainingProps
  } = props;
  const modalContext = useMemo(() => ({
    onClose,
  }), [onClose]);
  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      {...remainingProps}
    >
      <ModalContext.Provider value={modalContext}>
        <ModalContainer className={className} data-automation={modalContainerDataAutomationName}>
          {children}
        </ModalContainer>
      </ModalContext.Provider>
    </MuiModal>
  );
};

export { ModalTitle, ModalConfirmButton, ModalCancelButton, ModalActionsContainer, ModalScrollableContainerOuter, ModalScrollableContainerInner };
