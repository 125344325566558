import React from 'react';
import {
  ModalBodyContainer,
  ActionButtonContainer,
  UserForwardActionButton,
  CancelButton,
  BodySmallRegText,
} from 'components/Modal/userModal';
import { STRINGS } from 'config';

const {
  USER_API_DELETE_STATUS_CODES: {
    PRACTICE_IN_JWT_MISSING_OR_INVALID,
    USER_CANNOT_DELETE_THEMSELVES,
    USER_EXISTS_IN_ANOTHER_ORGANISATION,
  },
} = STRINGS;

const areYouSure = 'Are you sure you want to delete the selected user from this account?';
const cannotDeleteUser = 'You cannot delete this user. Please contact eConsult via Live Chat to get this user removed from your account.';
const cannotDeleteYourself = 'You cannot delete yourself. Please contact eConsult via Live Chat to remove yourself from this account.';

function getBodyText(userDeleteStatus) {
  switch (userDeleteStatus) {
    case PRACTICE_IN_JWT_MISSING_OR_INVALID: return cannotDeleteUser;
    case USER_CANNOT_DELETE_THEMSELVES: return cannotDeleteYourself;
    case USER_EXISTS_IN_ANOTHER_ORGANISATION: return cannotDeleteUser;
    case null: return areYouSure;
    default: return cannotDeleteUser;
  }
}

export const DeleteUserModalContent = ({
  onClose,
  handleSubmitDeleteUser,
  shouldBlockUserActions,
  userDeleteStatus,
}) => {
  const hasError = userDeleteStatus !== null;
  return (
    <ModalBodyContainer style={{ textAlign: 'center' }}>
      <BodySmallRegText data-automation="DeleteUserModalBodyText">
        {getBodyText(userDeleteStatus)}
      </BodySmallRegText>
      <ActionButtonContainer hasError={hasError}>
        {hasError
          ? (
            <UserForwardActionButton
              disabled={shouldBlockUserActions}
              onClick={onClose}
              data-automation="DismissButton"
              styled={{ alignSelf: 'center' }}
              type="button"
            >
              Dismiss
            </UserForwardActionButton>
          ) : (
            <>
              <CancelButton disabled={shouldBlockUserActions} onClick={onClose} data-automation="CancelDeleteUserButton">Cancel</CancelButton>
              <UserForwardActionButton
                disabled={shouldBlockUserActions}
                onClick={selectedEmail => handleSubmitDeleteUser(selectedEmail)}
                data-automation="DeleteUserButton"
                type="button"
              >
                Delete
              </UserForwardActionButton>
            </>
          )}

      </ActionButtonContainer>
    </ModalBodyContainer>
  );
};
