import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { STRINGS } from 'config';
import useReducerLogger from 'store/customLogger';
import { rootReducer, rootState } from 'store/reducer';

const { IS_DEV_OR_TEST_ENV } = STRINGS.ENVIRONMENT;

export const SettingsStore = createContext({
  state: rootState,
  dispatch: () => '',
});

export const StoreProvider = ({ children, initialState = rootState }) => {
  const loggedReducer = useReducerLogger(rootReducer);
  const [state, dispatch] = useReducer(IS_DEV_OR_TEST_ENV ? loggedReducer : rootReducer, initialState);
  const store = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <SettingsStore.Provider value={store}>{children}</SettingsStore.Provider>
  );
};

export const useAppState = () => {
  const { state } = useContext(SettingsStore);

  return state;
};

export const useAppDispatch = () => {
  const { dispatch } = useContext(SettingsStore);

  return dispatch;
};
