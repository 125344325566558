import { SaveButton, MoreInfoWrapper, Notification } from 'components/DemandManagement/style';
import { Radio } from 'components/Radio';
import { RadioGroup } from 'components/Radio/style';
import { Divider, InlineLink, ParagraphXSmallHeavy, ParagraphXSmallRegular, TextFlow, Heading } from 'components/Typography';
import { STRINGS } from 'config';
import { useAppDispatch, useAppState } from 'store/configureStore';
import React, { useEffect, useMemo } from 'react';
import {
  apiGetAdminAvailability,
  apiPostAdminAvailability,
  getDemandManagementEnabled,
  updateAdminAvailability,
} from 'store/action/demandManagement';
import { CustomAnchoredToolTip, TableContainer, TableHeaderDivider, TableHeaderSmallText, TableHeaderTitleText } from 'components/Table';
import { HeaderColumn, HeaderRow } from 'components/Table/tableHeader';
import { Form, Legend } from 'components/Form';
import { ExternalLink } from 'components/ExternalLink';
import { useLocation, useNavigate } from 'react-router-dom';

const {
  PRIMARY_CARE_DOMAIN,
  DEMAND_MANAGEMENT_HELP_URL,
  FEATURE_VALUES: { ADMIN_AVAILABILITY },
  REQUEST_STATUS: { PENDING, IDLE },
} = STRINGS;

const DMDisabledNoticeText = () => (
  <>
    Your practice doesn&apos;t have demand management enabled. If you need to change this, please contact us through Live Chat or email at&nbsp;
    <InlineLink href="mailto:practice.support@econsult.health">practice.support@econsult.health</InlineLink>.
  </>
);

export const DemandManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    app: {
      selectedPractice,
      selectedPracticeIsEhub,
      selectedPracticeHasAdminRights,
    },
    demandManagement: {
      adminAvailability,
      updateAdminAvailabilityRequestStatus,
      demandManagementEnabled,
      demandManagementEnabledStatus,
    },
  } = useAppState();

  const demandManagementSettingUrl = useMemo(() => (
    `https://${selectedPractice.id}.${PRIMARY_CARE_DOMAIN}/manage/${selectedPractice.pcmCode}`
  ), [selectedPractice.id, selectedPractice.pcmCode]);

  const isSaveDisabled = useMemo(() => (
    !selectedPracticeHasAdminRights
    || selectedPracticeIsEhub
    || updateAdminAvailabilityRequestStatus === PENDING
    || !demandManagementEnabled
  ), [selectedPracticeHasAdminRights, selectedPracticeIsEhub, updateAdminAvailabilityRequestStatus, demandManagementEnabled]);

  const isValueValidForFeature = useMemo(() => Object.keys(ADMIN_AVAILABILITY).includes(adminAvailability?.id), [adminAvailability]);
  const showDMDisabledNotice = !demandManagementEnabled && ![PENDING, IDLE].includes(demandManagementEnabledStatus);

  const onChangeOpeningHours = event => {
    dispatch(updateAdminAvailability(event.target.value));
  };

  const onSaveOpeningHours = () => {
    apiPostAdminAvailability(selectedPractice.id, adminAvailability?.id)(dispatch);
  };

  useEffect(() => {
    if (!selectedPracticeIsEhub) {
      apiGetAdminAvailability(selectedPractice.id)(dispatch);
      getDemandManagementEnabled(selectedPractice.id)(dispatch);
    }
  }, [dispatch, selectedPractice.id, selectedPracticeIsEhub]);

  useEffect(() => {
    if (selectedPracticeIsEhub) {
      navigate('/user-management');
    }
  }, [location, navigate, selectedPracticeIsEhub]);

  return (
    <TableContainer id="SkipToUserListMainContent" data-automation="DemandManagementWrapper">
      <HeaderColumn>
        <HeaderRow>
          <HeaderColumn>
            <TableHeaderTitleText as="h1" data-automation="DemandManagementPageTitleText">
              Demand management
            </TableHeaderTitleText>

            <TableHeaderSmallText data-automation="DemandManagementPageSubTitleText">
              Manage your eConsult demand to suit your practice&apos;s needs.
            </TableHeaderSmallText>
          </HeaderColumn>
        </HeaderRow>

        <TableHeaderDivider />
      </HeaderColumn>

      {showDMDisabledNotice && (
        <Notification automationName="demandManagementDisabledNotice" textContent={<DMDisabledNoticeText />} />
      )}

      <TextFlow>
        <ParagraphXSmallHeavy as="h2">Administrative requests opening hours</ParagraphXSmallHeavy>

        <Form action="" onChange={onChangeOpeningHours} onSubmit={event => event.preventDefault()}>
          <RadioGroup>
            <div>
              <Legend>
                This choice lets your practice keep administrative requests available to patients
                even if our clinical pathways are closed. Even though we direct patients to other
                services and provide clear messages, there&apos;s still a chance a patient might
                send a clinical request through an administrative channel.
              </Legend>

              {Object.values(ADMIN_AVAILABILITY).map(({ id, label }) => (
                <Radio
                  key={`${selectedPractice.id}--${id}`}
                  id={id}
                  fieldName="adminAvailability"
                  value={id}
                  isChecked={id === adminAvailability?.id}
                  defaultChecked={id === adminAvailability?.id && isValueValidForFeature}
                  onChange={onChangeOpeningHours}
                  label={label}
                />
              ))}
            </div>
          </RadioGroup>

          <ParagraphXSmallRegular>
            Unless you&apos;ve changed them before, your practice opening hours will be
            set as 8am to 6:30pm, Monday to Friday. If you have any questions, please
            contact us through Live Chat or email at&nbsp;

            <InlineLink href="mailto:practice.support@econsult.health">
              practice.support@econsult.health
            </InlineLink>.
          </ParagraphXSmallRegular>

          <Divider />

          <SaveButton onClick={onSaveOpeningHours} disabled={isSaveDisabled} type="submit" data-automation="SaveDemandManagementButton">
            Save changes
            {!selectedPracticeHasAdminRights && (
            <CustomAnchoredToolTip
              automationLabel="SaveDemandManagementDisabledTooltip"
              label="Contact your practice admin"
              align="top"
            />
            )}
          </SaveButton>
        </Form>

        <MoreInfoWrapper>
          <Heading as="h3">More about demand management</Heading>

          {demandManagementEnabled && (
            <ParagraphXSmallRegular>
              Make changes to other&nbsp;
              <ExternalLink url={demandManagementSettingUrl} label="demand management settings." automationName="DemandManagementWebapp" />
            </ParagraphXSmallRegular>
          )}

          <ParagraphXSmallRegular>
            Find out more about our demand management functionality in our&nbsp;
            <ExternalLink url={DEMAND_MANAGEMENT_HELP_URL} label="help centre." automationName="DemandManagementHelpCentre" />
          </ParagraphXSmallRegular>
        </MoreInfoWrapper>
      </TextFlow>
    </TableContainer>
  );
};
