import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import styled from 'styled-components';

export const RadioGroup = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;

  & > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.5rem;
    row-gap: 0.8rem;
  }
`;

export const RadioWrapper = styled.label`
  display: flex;
  gap: 0.25rem;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin: 0;
  accent-color: ${({ theme }) => theme.colorActionPrimaryDefault};

  &&:focus-visible,
  &&:focus {
    outline: Highlight solid 2px;
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const RadioLabel = styled(Paragraph).attrs({
  as: 'span',
  typography: 'ParagraphTinyRegular',
})``;
